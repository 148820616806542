import { default as abuseReports1Wupap5JgmMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93pn29vRwc2BMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as indexOO0v9HVDCrMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/admin/index.vue?macro=true";
import { default as moderationvu6Vtdo6zEMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/admin/moderation.vue?macro=true";
import { default as pendingBansoN9ehzTlelMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/admin/pendingBans.vue?macro=true";
import { default as profilesJiW7N4n8oDMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/admin/profiles.vue?macro=true";
import { default as expensesl5bliiEtxIMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexWkqiaWngRJMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewYhdN6H3HCbMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingc79eAhFYI5Meta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingtubIDCaL8VMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/admin/translations/missing.vue?macro=true";
import { default as usersFGQPCXO7rKMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/admin/users.vue?macro=true";
import { default as apiHZzVTqdIr9Meta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/api.vue?macro=true";
import { default as _91slug_93WQTEGRMBN5Meta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/blog/[slug].vue?macro=true";
import { default as indexS3vu8JafoUMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93BnSpcXq4IPMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93dnThLn6xTQMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminkoz47sX1smMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/census/admin.vue?macro=true";
import { default as index0PiFnwYzOSMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/census/index.vue?macro=true";
import { default as contactdkoJiREskEMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/contact.vue?macro=true";
import { default as designeqdUnpNoCQMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/design.vue?macro=true";
import { default as englishEMEM5rhq5yMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/english.vue?macro=true";
import { default as faqVaaPyCSM8AMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/faq.vue?macro=true";
import { default as inclusiveXR0rUyjmrDMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/inclusive.vue?macro=true";
import { default as indexL4YSuftM7iMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/index.vue?macro=true";
import { default as licenseb90Q7khYRVMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/license.vue?macro=true";
import { default as academicjY2eGN1CrXMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/links/academic.vue?macro=true";
import { default as indexGfAO102agGMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/links/index.vue?macro=true";
import { default as mediapjQmFbQq5FMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/links/media.vue?macro=true";
import { default as translinguistics29fRi8uf5eMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/links/translinguistics.vue?macro=true";
import { default as zinegB1M9Jh9xrMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/links/zine.vue?macro=true";
import { default as namesDtUvhiKF2fMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/names.vue?macro=true";
import { default as indexE6cgzFDTenMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/nouns/index.vue?macro=true";
import { default as templatesd8TyFA0VNZMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/nouns/templates.vue?macro=true";
import { default as people6LQgX5P1gqMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/people.vue?macro=true";
import { default as privacymxFSlZUVC1Meta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/privacy.vue?macro=true";
import { default as _91username_93NpLlxveweYMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_938dKNdeZHN2Meta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/profile/card-[username].vue?macro=true";
import { default as editorqNrNJUQM8rMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/profile/editor.vue?macro=true";
import { default as anyJAnBtNOpO0Meta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/pronouns/any.vue?macro=true";
import { default as avoiding8ofRRuSx5CMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/pronouns/avoiding.vue?macro=true";
import { default as index4WxY6AZH7oMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/pronouns/index.vue?macro=true";
import { default as mirror9VKph0Fh9UMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/pronouns/mirror.vue?macro=true";
import { default as pronounmKhnZYnGYfMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesV6eilnmIcqMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/sources.vue?macro=true";
import { default as teamDWKWkqIUtKMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/team.vue?macro=true";
import { default as terminologyVxu0LNEQHrMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/terminology.vue?macro=true";
import { default as termsD9liiW2EXOMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/terms.vue?macro=true";
import { default as usercZcRciusBgMeta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/user.vue?macro=true";
import { default as workshops2unIGvHF64Meta } from "/home/admin/www/en.pronouns.page/release/20241012151037/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReports1Wupap5JgmMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansoN9ehzTlelMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apiHZzVTqdIr9Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93WQTEGRMBN5Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexS3vu8JafoUMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93BnSpcXq4IPMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93dnThLn6xTQMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminkoz47sX1smMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: index0PiFnwYzOSMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactdkoJiREskEMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishEMEM5rhq5yMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqVaaPyCSM8AMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusiveXR0rUyjmrDMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/inclusive.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicjY2eGN1CrXMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexGfAO102agGMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediapjQmFbQq5FMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguistics29fRi8uf5eMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zinegB1M9Jh9xrMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namesDtUvhiKF2fMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexE6cgzFDTenMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesd8TyFA0VNZMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: people6LQgX5P1gqMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacymxFSlZUVC1Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93NpLlxveweYMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_938dKNdeZHN2Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editorqNrNJUQM8rMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyJAnBtNOpO0Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoiding8ofRRuSx5CMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: index4WxY6AZH7oMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirror9VKph0Fh9UMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounmKhnZYnGYfMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesV6eilnmIcqMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamDWKWkqIUtKMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyVxu0LNEQHrMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsD9liiW2EXOMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: usercZcRciusBgMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshops2unIGvHF64Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241012151037/pages/workshops.vue")
  }
]